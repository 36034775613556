export const paymentSystemItems = [
  {
    key: "0",
    value: "stripe",
    img: "/images/stripe.png",
    title: "How to integrate Bounce with Stripe"
  },
  {
    key: "1",
    value: "braintree",
    img: "/images/braintree.png",
    title: "How to integrate Bounce with Braintree"
  },
  {
    key: "2",
    value: "paypal",
    img: "/images/paypal.png",
    title: "How to integrate Bounce with PayPal"
  },
  {
    key: "3",
    value: "partner_gateway",
    img: "/images/bounce_logo.svg",
    title: "Gateway Partner Integration"
  }
];
export const enviornmentItems = [
  {
    value: "Staging",
    environment: "staging",
    key: "0",
  },
  {
    value: "Production",
    environment: "production",
    key: "1",
  },
];

export const languageItems = [
  {
    value: "Python - requests",
    key: "0",
    displayText: "python",
  },
  {
    value: "NodeJS native",
    key: "1",
    displayText: "nodejs",
  },
  {
    value: "PHP http",
    key: "2",
    displayText: "php",
  },
  {
    value: "Java Unirest",
    key: "3",
    displayText: "java",
  },
  {
    value: "Curl",
    key: "4",
    displayText: "curl",
  },
  {
    value: "Ruby",
    key: "5",
    displayText: "ruby",
  },
];
