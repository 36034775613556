import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { RxCopy } from "react-icons/rx";
import { copyText } from "../../../utils/helperFunctions";
import JSONPretty from "react-json-pretty";
import styles from "./CodeDisplay.module.scss";
import InformationCollected from "./InformationCollected";
import {
  stripePostDeclineRawJson,
  braintreePostDeclineRawJson,
  genericPostDeclineRawJson
} from "../../../utils/codeExampleVariables";
import NavContext from "../../../context/NavContext";

function PlainText({ titleText, bouncepay, markup, type, parameters }) {
  const [textToCopy, setTextToCopy] = useState("");
  const { paymentSystem } = useContext(NavContext);

  const postDeclineRawJson =
    paymentSystem?.key === "0"
      ? stripePostDeclineRawJson
      : (paymentSystem?.key === "1" ? braintreePostDeclineRawJson: genericPostDeclineRawJson);

  useEffect(() => {
    if (bouncepay) {
      setTextToCopy(`bouncepay.${type}(${parameters ? parameters : ""})`);
    } else if (titleText === "json") {
      setTextToCopy(JSON.stringify(postDeclineRawJson, undefined, 2));
    } else {
      const informationCollected = document.getElementById(
        "informationCollected"
      )?.innerText;
      setTextToCopy(informationCollected);
    }
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        {titleText ? <p className={styles.plainText}>{titleText}</p> : <p></p>}
        <RxCopy
          className={styles.copyIcon}
          onClick={() => copyText(textToCopy)}
        />
      </div>
      <div className={styles.innerContainer}>
        {bouncepay ? (
          <p className={styles.bodyText}>
            <span className={styles.smallText}>bouncepay.</span>
            <span className={`${styles.smallText} ${styles.function}`}>
              {type}
            </span>
            <span className={styles.smallText}>{`(${
              parameters ? parameters : ""
            })`}</span>
          </p>
        ) : markup ? (
          <div className={styles.jsonCodeContainer} id="markup-text">
            <JSONPretty
              style={{ backgroundColor: "none !important" }}
              id="json-pretty"
              data={postDeclineRawJson}
            />
          </div>
        ) : (
          <InformationCollected />
        )}
      </div>
    </div>
  );
}

PlainText.propTypes = {
  bouncepay: PropTypes.bool,
  markup: PropTypes.bool,
  type: PropTypes.string,
  titleText: PropTypes.string,
  parameters: PropTypes.string,
};

export default PlainText;
