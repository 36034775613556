import React, { useContext, useEffect, useRef } from "react";
import NavContext from "../../../../../../context/NavContext";
import SideMenuContext from "../../../../../../context/SideMenuContext";
import { scrollToElement } from "../../../../../../utils/helperFunctions";
import HeadersAndParams from "../../../../../Shared/UrlExample/HeadersAndParams/HeadersAndParams";
import UrlExample from "../../../../../Shared/UrlExample/UrlExample";
import styles from "../../../TextContent.module.scss";

function CancelTransactionRetry() {
  const { toggleScroll, scroll } = useContext(SideMenuContext);
  const { apiOrSandbox, paymentSystem } = useContext(NavContext);
  const apiCall = paymentSystem?.key === "0" ? "PUT" : "POST";
  const baseUrl = paymentSystem?.key === "3" ? "v1/decision" : "api/v1/transaction"
  
  const myRef = useRef();
  useEffect(() => {
    scrollToElement(
      myRef,
      scroll.cancelTransactionRetry,
      scroll.setCancelTransactionRetry,
      toggleScroll
    );
  }, [scroll.cancelTransactionRetry]);
  return (
    <div ref={myRef} className={styles.transactionTextContainer}>
      <h2 className={styles.subTitle}>
        <span
          className={`${
            paymentSystem?.key === "0" ? styles.blueText : styles.orangeText
          } ${styles.titleSpace}`}>
          {apiCall} {""}
        </span>
        Cancel Transaction Retry
      </h2>
      <UrlExample
        text={`https://{{merchant_name}}.${apiOrSandbox}.bouncepay.io/${baseUrl}/{{transaction_id}}/cancel`}
      />
      <p className={`${styles.span} ${styles.mediumText}`}>
        This transaction is used to inform Bounce that a transaction that was
        previously sent with the decline API no longer needs to be retried by
        Bounce. <br /> A common use case is where Bounce approved a declines
        transaction and the buyer later asked to cancel the purchase. In that
        instance, Bounce must not retry the transaction. <br /> If this call is
        made after Bounce has already successfully retried the transaction, an
        error response will be returned, and the merchant will need to cancel
        the new transaction on their side.
      </p>
      <HeadersAndParams apiKey={true} apiKeyValue="TEST_KEY" />
    </div>
  );
}

export default CancelTransactionRetry;
