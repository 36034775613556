import React, { useContext } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { Card } from "antd";
import styles from "./PaymentSystemCards.module.scss";
import NavContext from "../../context/NavContext";
import { Typography } from "antd";
import { routes } from "../../routes/routes";
import SideMenuContext from "../../context/SideMenuContext";
// import { capitalizeFirstLetter } from "../../utils/helperFunctions";

const { Title } = Typography;

function PaymentSystemCard({ item, paymentSystemType }) {
  const { setPaymentSystem } = useContext(NavContext);
  const { setOpenSection } = useContext(SideMenuContext);

  const navigate = useNavigate();

  const handleClick = () => {
    setPaymentSystem(item);
    setOpenSection("0");
    if (paymentSystemType === "paypal") {
      navigate(routes.paypal.main);
    } else {
      navigate(routes.apiEndpoints.main(item.value.toLowerCase()));
    }
  };

  return (
    <Card hoverable className={styles.card} onClick={handleClick}>
      <div className={styles.innerContainer}>
        <div className={styles.imgContainer}>
          <img src={item?.img} alt={item?.value} className={styles.img} />
        </div>
        <Title level={5}>
          {item.title}
        </Title>
      </div>
    </Card>
  );
}

PaymentSystemCard.propTypes = {
  item: PropTypes.object,
  paymentSystemType: PropTypes.string,
};
export default PaymentSystemCard;
