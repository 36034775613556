export const paymentSystems = {
  stripe: "stripe",
  braintree: "braintree",
  paypal: "paypal",
  adyen: "adyen",
  bluesnap: "bluesnap",
  partner_gateway: "partner_gateway"
};

export const paymentSystemSectionKey = {
  stripe: "5",
  braintree: "6",
  paypal: "7",
  partner_gateway: "8",
  bluesnap: "9",
};

