import React, { useContext, useEffect } from "react";
import styles from "../../TextContent.module.scss";
import Transaction from "./transaction";
import WebhookEvent from "../WebhookEvent";
// import ExampleCode from "../../../CodeDisplay/ExampleCode";
// import {
//   webhookEventHeaders,
//   webhookEventSelectOptions,
//   webhookEventSuccessfulResponse,
// } from "../../../../../utils/codeExampleVariables";
// import WebhookEventRequests from "../../../CodeDisplay/ExampleRequests/WebhookEventRequests/TransactionPostDeclineRequests";
import NavContext from "../../../../../context/NavContext";
import { useParams } from "react-router-dom";
import { paymentSystemItems } from "../../../../NavBar/variables";

function ApiV1() {
  const { isStripe, setPaymentSystem } = useContext(NavContext);

  let params = useParams();

  useEffect(() => {
    if (params.paymentSystem) {
      const paymentSystem = paymentSystemItems.find(
        (item) => item.value.toLowerCase() === params.paymentSystem
      );
      setPaymentSystem(paymentSystem);
    }
  }, [params]);

  return (
    <div className={styles.innerContentContainer}>
      <Transaction />
      {isStripe && (
        <div className={styles.transactionTextAndCode}>
          <WebhookEvent />
          {/* <ExampleCode
            id="webhook-event"
            successfulResponse={webhookEventSuccessfulResponse}
            headers={webhookEventHeaders}
            dropdownOptions={webhookEventSelectOptions}
            exampleRequest={<WebhookEventRequests />}
          /> */}
        </div>
      )}
    </div>
  );
}

export default ApiV1;
